<template lang="">
  <div class="annex">
    <NavBar title="认证审核" left-arrow @click-left="onClickLeft" />
    <div class="content">
      <div class="top">
        <div class="left">
          <img class="text" src="/images/icon-annex-text.png" alt="" />
          <span>Legal attachment</span>
        </div>
        <div class="right"></div>
      </div>
      <div class="list">
        <Field
          v-for="(item, index) in list"
          :key="index"
          :is-link="false"
          readonly
          :label="item.fileName"
          @click="check(item)"
        >
          <template #button>
            <Button size="small" type="primary">查看</Button>
          </template>
        </Field>
      </div>
      <img
        :src="demosrc"
        crossOrigin="anonymous"
        ref="demoimg"
        class="unshow-demo"
      />
    </div>
    <PdfViewer ref="PdfViewer" :pdfUrl="pdfUrl"></PdfViewer>
  </div>
</template>
<script>
import { NavBar, Field, Button, ImagePreview, Toast } from "vant";
import PdfViewer from "@/components/PdfViewer.vue";
import Api from "@/api/index.js";

export default {
  components: { NavBar, Field, Button, PdfViewer },
  data() {
    return {
      list: [],
      demosrc: "",
      pdfUrl: "",
    };
  },
  created() {
    this.legalBizNo = this.$route.query.legalBizNo;
    this.getAtt();
  },
  methods: {
    getAtt() {
      Api.attchment(this.legalBizNo).then(({ data, success }) => {
        if (success) {
          this.list = data;
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    waitImgLoad(img, url) {
      return new Promise((resp) => {
        img.src = url;
        img.onload = () => {
          resp(img);
        };
      });
    },
    async setImgMark(list) {
      for (let i = 0; i < list.length; i++) {
        let e = list[i];
        let img = await this.waitImgLoad(this.$refs.demoimg, e);
        let width = img.width / Math.max(Math.floor(img.width / 1000), 1); //document.body.clientWidth * 0.3839;
        let height = img.height / Math.max(Math.floor(img.width / 1000), 1); //document.body.clientWidth * 0.2159;
        let c2 = document.createElement("canvas"); // 水印图文字 画布
        let tt = "scw";
        let marksize = width / 5; //(tt.length + 2) * ftsz; // 设置水印图大小
        let ftsz = marksize / (tt.length + 2); //20; // font size
        let ctx2 = c2.getContext("2d");
        c2.width = marksize;
        c2.height = marksize;
        ctx2.font = `${ftsz}px  Microsoft YaHei`; //
        ctx2.fillStyle = "#0006";
        ctx2.textAlign = "center";
        ctx2.textBaseline = "Middle";
        ctx2.translate(marksize / 2, marksize / 2);
        ctx2.rotate(Math.PI / 4);
        ctx2.fillText(tt, 0, 0);
        let c = document.createElement("canvas"); // 重绘图 画布
        c.width = width;
        c.height = height;
        let ctx = c.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height); // 绘制原图
        // 绘制水印图到原图上
        for (let i = 0; i * marksize <= width + marksize; i++) {
          for (let j = 0; j * marksize <= height + marksize; j++) {
            ctx.drawImage(
              c2,
              i * marksize - marksize / 2, // 水印整体左移
              j * marksize - (i % 3) * (marksize / 3), // 水印错落 y轴
              marksize,
              marksize
            );
          }
        }

        let url = c.toDataURL("image/png");
        list[i] = url;
      }
      return list;
    },
    async check(item) {
      if (item.attachmentUrl && item.attachmentUrl.endsWith(".pdf")) {
        this.pdfUrl = item.attachmentUrl;
        return this.$refs.PdfViewer.show();
      } else if (
        item.attachmentUrl &&
        /\.(jpg|png|jpeg|gif)$/.test(item.fileName)
      ) {
        let data = this.list
          .filter((item) => /\.(jpg|png|jpeg|gif)$/.test(item.fileName))
          .map((item) => item.attachmentUrl);
        if (data.length) {
          let list = await this.setImgMark(data);
          ImagePreview({
            images: list,
            startPosition: 0,
            closeable: true,
          });
        } else {
          Toast("没有图片附件");
        }
      } else {
        let a = document.createElement("a");
        a.href = item.attachmentUrl;
        a.download = true;
        a.click();
        document.body.appendChild(a);
        setTimeout(() => {
          document.body.removeChild(a); // 删除链接元素
        }, 0);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.annex {
  .content {
    padding: 15px 12px;
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .left {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        justify-content: space-around;
        .text {
          width: 90px;
          height: 21px;
          object-fit: contain;
        }
      }
      .right {
        background-image: url("/images/icon-annex.png");
        background-size: contain;
        width: 58px;
        height: 64px;
      }
    }
    /deep/.van-cell__title {
      display: flex;
      width: 60%;
      align-items: center;
    }
  }
  .unshow-demo {
    opacity: 0;
    width: unset;
    height: unset;
    position: absolute;
    z-index: -1;
  }
}
</style>
